import request from '@/plugins/axios'

// 平台配置

// 获取平台信息
export const apiBaseConfig = () => request.get('/settings.platform/getBaseConfig')

// 设置平台信息
export const apiBaseConfigEdit = (params: any) =>
    request.post('/settings.platform/setBaseConfig', params)

// 获取平台备案信息
export const apiRecordConfig = () => request.get('/settings.platform/getRecordConfig')

// 设置平台备案信息
export const apiRecordConfigEdit = (params: any) =>
    request.post('/settings.platform/setRecordConfig', params)

// 系统设置

// 获取系统日志列表
export const apiSystemlogList = (params: any) => request.get('/settings.log/lists', { params })

// 清除系统缓存
export const apiSystemCacheClear = () => request.post('/settings.cache/clear')

// 定时任务列表
export const apiCrontabLists = (params: any) => request.get('/crontab.crontab/lists', { params })

// 添加定时任务
export const apiCrontabAdd = (params: any) => request.post('/crontab.crontab/add', params)

// 查看详情
export const apiCrontabDetail = (params: any) => request.get('/crontab.crontab/detail', { params })

// 编辑定时任务
export const apiCrontabEdit = (params: any) => request.post('/crontab.crontab/edit', params)

// 删除定时任务
export const apiCrontabDel = (params: any) => request.post('/crontab.crontab/delete', params)

// 获取规则执行时间
export const apiCrontabExpression = (params: any) =>
    request.get('/crontab.crontab/expression', { params })

// 操作定时任务
export const apiSrontabOperate = (params: any) => request.post('/crontab.crontab/operate', params)

/** E 系统维护 **/

/** S 系统更新 **/
// 系统更新列表
export const apiSystemUpgradeLists = (params: any) => request.get('/settings.upgrade/lists')

// 下载更新包
export const apiSystemUpgradeDownloadPkg = (params: any) =>
    request.post('/settings.upgrade/downloadPkg', params)

// 一键更新
export const apiSystemUpgrade = (params: any) => request.post('/settings.upgrade/upgrade', params)
/** E 系统更新 **/

// 系统环境
export const apiSystemSystemEnv = () => request.get('/settings.env/systemEnv')

/** S 客服设置 **/
// 客服获取
export const apiServiceGet = () => request.get('/settings.service/getConfig')

// 客服设置
export const apiServiceSet = (params: any) => request.post('/settings.service/setConfig', params)
/** E 客服设置 **/

/** S 积分列表页面（用户端） **/
// 客服获取
export const apiIntegralGet = () => request.get('/settings.integral/getConfig')

// 客服设置
export const apiIntegralSet = (params: any) => request.post('/settings.integral/setConfig', params)
/** E 客服设置 **/

/** S 安全设置 **/
// 修改管理员密码
export const apiResetPassword = (params: any) => request.post('/Login/resetPassword', params)
/** E 安全设置 **/

/** S 存储设置 **/
// 获取存储引擎列表
export const apiStorageList = (): Promise<any> => request.get('/settings.Storage/lists')

// 获取存储配置信息
export const apiStorageIndex = (params: any): Promise<any> =>
    request.get('/settings.Storage/index', { params })

// 更新配置
export const apiStorageSetup = (params: any): Promise<any> =>
    request.post('/settings.Storage/setup', params)

// 切换默认存储引擎
export const apiStorageChange = (params: any): Promise<any> =>
    request.post('/settings.Storage/change', params)
/** E 存储设置 **/

// 支付配置
export const apiPaymentConfigSet = (params: any): Promise<any> =>
    request.post('/settings.pay_config/setConfig', params)

// 获取支付配置
export const apiPaymentConfigGet = (params: any): Promise<any> =>
    request.get('/settings.pay_config/getConfig', { params })

// 获取支付配置的列表
export const apiPaymentConfigGetList = (): Promise<any> => request.get('/settings.pay_config/lists')
// 更新支付方式
export const apiUpgradeAfter = (params: any): Promise<any> =>
    request.post('/UpgradeAfter/run', params)


/** S 物流接口 **/

// 获取物流接口
export const apideliveryWay = () => request.get('settings.delivery.delivery_way/getConfig')

// 设置物流接口
export const apideliveryWayset = (data: any) =>
    request.post('settings.delivery.delivery_way/setConfig', data)

/** S 物流接口 **/

/** S 快递公司 **/

// 新增快递公司
export const apiExpressAdd = (data: any) => request.post('/settings.delivery.express/add', data)

// 编辑快递公司
export const apiExpressEdit = (data: any) => request.post('/settings.delivery.express/edit', data)

// 删除快递公司
export const apiExpressDel = (data: any) => request.post('/settings.delivery.express/del', data)

// 快递公司详情
export const apiExpressDetail = (params: any) =>
    request.get('/settings.delivery.express/detail', { params })

// 快递公司列表
export const apiExpressLists = (params: any) =>
    request.get('/settings.delivery.express/lists', { params })

/** E 快递公司 **/

/** S 物流接口 **/

// 获取物流接口
export const apiLogisticsConfig = () =>
    request.get('settings.delivery.logistics_config/getLogisticsConfig')

// 设置物流接口
export const apiSetLogisticsConfig = (data: any) =>
    request.post('settings.delivery.logistics_config/setLogisticsConfig', data)

/** S 物流接口 **/

/** S 运费模板 **/

// 添加运费模板
export const apiFreightAdd = (data: any) => request.post('settings.delivery.freight/add', data)

// 编辑运费模板
export const apiFreightEdit = (data: any) => request.post('settings.delivery.freight/edit', data)

// 运费模板列表
export const apiFreightLists = (params: any) =>
    request.get('settings.delivery.freight/lists', { params })

// 运费模板详情
export const apiFreightDetail = (params: any) =>
    request.get('settings.delivery.freight/detail', { params })

// 删除运费模板
export const apiFreightDel = (data: any) => request.post('settings.delivery.freight/del', data)
/** S 运费模板 **/

